import { render, staticRenderFns } from "./default.vue?vue&type=template&id=99d19c04"
import script from "./default.vue?vue&type=script&lang=ts"
export * from "./default.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavigationStepsCheckout: require('/home/runner/work/webapp/webapp/components/molecules/navigation/NavigationStepsCheckout.vue').default,HeaderLite: require('/home/runner/work/webapp/webapp/components/organisms/header/HeaderLite.vue').default,HeaderBanners: require('/home/runner/work/webapp/webapp/components/organisms/header/HeaderBanners.vue').default,HeaderMain: require('/home/runner/work/webapp/webapp/components/organisms/header/HeaderMain.vue').default,FooterMain: require('/home/runner/work/webapp/webapp/components/organisms/footer/FooterMain.vue').default,ModalAppDownloadPrompt: require('/home/runner/work/webapp/webapp/components/organisms/modal/ModalAppDownloadPrompt.vue').default,DialogBackdrop: require('/home/runner/work/webapp/webapp/components/atoms/dialog/DialogBackdrop.vue').default,BaseSidebar: require('/home/runner/work/webapp/webapp/components/molecules/sidebar/BaseSidebar.vue').default})
